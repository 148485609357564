<template>
	<div class="carousel--application">
		<div class="carousel--container">
			<div class="carousel--wrapper">
				<div class="carousel--item" v-for="(image, index) in gallery" :key="index" :order="index+1">
					<div class="carousel--item-inner">
						<img :src="image">
					</div>
				</div>
			</div>
		</div>
		<div class="carousel--text">
			<h3 v-for="(title, index) in galleryText.split('#')" :key="index">{{title}}</h3>
		</div>
		<div class="carousel--arrow-prev" :class="this.currents == 1 ? 'inactive' : ''">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
				<polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "/>
			</svg>
		</div>
		<div class="carousel--arrow-next" :class="this.currents == this.totalSlides ? 'inactive' : ''">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
				<polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "/>
			</svg>
		</div>
	</div>
</template>

<script>
import Draggabilly from 'draggabilly';
import gsap from 'gsap'
export default {
	data(){
		return {
			carousel: '',
			container: '',
			currents: 1,
			prevDirection: 0,
			direction: 0,
			dir: '',
			wizSize: {width: window.innerWidth, height: window.innerHeight},
			totalSlides: 0
		}
	},
	props: {
		gallery: {
			type: Array,
			required: true,
		},
		galleryText: {
			type: String,
			required: true,
		},
		triggered: {
			type: Boolean,
		},
	},
	methods: {
		calcWinsize(){
			this.wizSize = { width:window.innerWidth, height: window.innerHeight}
		},
		isIpadPro() {
			let response;
			let ratio = window.devicePixelRatio || 1;
			let screen = {
				width : window.screen.width * ratio,
				height : window.screen.height * ratio
			};
			if(
			(screen.width === 2048 && screen.height === 2732) || //iPad Pro Portrait
			(screen.width === 2732 && screen.height === 2048) || //iPad Pro Landscape
			(screen.width === 1536 && screen.height === 2048) || //iPad
			(screen.width === 2048 && screen.height === 1536)){	
				response = true;
			}else {
				response = false;
			}
			return response;
		},
		isMobile() {
			if(this.wizSize.width <= 768 || this.isIpadPro()){
				return true;
			}else{
				return false;
			}
		},
		initCarousel() {
			this.container = document.querySelector('.carousel--container')

			this.carousel = new Draggabilly( this.container, {
				axis: 'x'
			});
		},
		setSliderWidth(){
			let width = 0;
			let elements = this.container.querySelector('.carousel--wrapper').querySelectorAll('.carousel--item');
			[...elements].forEach((el) => {
				let style = el.currentStyle || window.getComputedStyle(el);
				width += (el.offsetWidth + parseFloat(style.marginRight));
			});
			let list = this.container.querySelector('.carousel--wrapper');
			list.style.width = (width + 'px');
    },
		animateCarousel() {
			let allSlides = this.container.querySelectorAll('.carousel--item').length;
			this.totalSlides = allSlides;
			let list = this.container.querySelector('.carousel--wrapper');
			let style = list.currentStyle || window.getComputedStyle(list);
			let list_width = list.offsetWidth - parseFloat(style.paddingLeft);
			let elements = list.querySelectorAll('.carousel--item');
			let first_el_rect = elements[0].getBoundingClientRect();
			let second_el_rect = elements[1].getBoundingClientRect();

			let last_element = elements[elements.length-1];
			let limit_end = (list_width - last_element.offsetWidth);
			//let total_left = (list_width - (last_element.offsetWidth / 2));

			let htmlElement = elements[0];
			let stl = window.getComputedStyle(htmlElement);
			let mrg = parseFloat(stl.marginRight);

			let prevBtn = document.querySelector('.carousel--arrow-prev');
			let nextBtn = document.querySelector('.carousel--arrow-next');

			elements[0].classList.add('current-active-slide');
			elements[1].classList.add('next-active-slide');

			let grid = {
				main: {
					start: first_el_rect.left,
					end: first_el_rect.right,
					width: first_el_rect.width,
					margin: mrg
				},
				next: {
					start: second_el_rect.left,
					end: second_el_rect.right,
					width: second_el_rect.width
				}
			}

			this.carousel.on('dragStart', () => {
				if(parseInt(this.currents) > 1){
					let prevSlide = parseInt(this.currents) - 1;
					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]').classList.remove('prev-active-slide');
				}

				if(parseInt(this.currents) < allSlides){
					let nextSlide = parseInt(this.currents) + 1;
					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]').classList.remove('next-active-slide');
				}
			});

			this.carousel.on( 'pointerMove', ( event ) => {
				let cursor = document.getElementById('cursor')
				gsap.to(cursor, {x: event.clientX, y: event.clientY, duration: 0.5, ease: 'power2.out'})
			});

			nextBtn.addEventListener('click', () => {
				if(parseInt(this.currents) < allSlides){
					let nextSlide = parseInt(this.currents) + 1;
					let currentElement = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]');
					let wrapper = currentElement.querySelector('.carousel--item-inner');

					let delta =  grid.main.start - currentElement.offsetLeft;
					gsap.to(this.container, {duration: 0.5, left: delta, ease: 'power2.out'});
					this.currents++;

					if(parseInt(this.currents) > 2){
						let prevPrevSlide = parseInt(this.currents) - 2;
						this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevPrevSlide + '"]').classList.remove('prev-active-slide');
					}

					if(parseInt(this.currents) > 0){
						let prevSlide = parseInt(this.currents) - 1;
						this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]').classList.remove('current-active-slide');
						this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]').classList.add('prev-active-slide');
						let el_3 = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]');
						let wp_3 = el_3.querySelector('.carousel--item-inner');
						if(el_3 !== null){
							if(this.isMobile()){
								gsap.to(wp_3, {duration: 0.5, x: '20%', y: '20%', scale: 0.6, ease: 'power2.out'})
							}else{
								gsap.to(wp_3, {duration: 0.5, x: '-50%', y: '20%', scale: 0.6, ease: 'power2.out'})
							}
						}
					}

					if(parseInt(this.currents) <= (allSlides - 1)){
							let nextSlide = parseInt(this.currents) + 1;
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]').classList.add('next-active-slide');
					}

					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.remove('next-active-slide');
					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.add('current-active-slide');
					gsap.to(wrapper, {duration: 0.5, x: '0%', y: '0%', scale: 0.8, ease: 'power2.out'})
				}
			})

			prevBtn.addEventListener('click', () => {
				if(parseInt(this.currents) > 1){
					let prevSlide = parseInt(this.currents) - 1;
					let currentElement = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]');
					//let currRect = currentElement.getBoundingClientRect();
					let wrapper = currentElement.querySelector('.carousel--item-inner');
					//let wrapper_img = currentElement.querySelector('.wrapper .image picture img');

					let delta =  grid.main.start - currentElement.offsetLeft;
					gsap.to(this.container, {duration: 0.5, left: delta, ease: 'power2.out'});

					this.currents--;

					if(parseInt(this.currents) > 1){
							let prevSlide = parseInt(this.currents) - 1;
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]').classList.add('prev-active-slide');
					}

					if(parseInt(this.currents) < allSlides){
							let nextSlide = parseInt(this.currents) + 1;
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]').classList.remove('current-active-slide');
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]').classList.add('next-active-slide');
							let el_1 = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]');
							let wp_1 = el_1.querySelector('.carousel--item-inner');
							if(el_1 !== null){
								if(this.isMobile()){
									gsap.to(wp_1, {duration: 0.5, x: '-20%', y: '-33%', scale: 0.6, ease: 'power2.out'})
								}else{
									gsap.to(wp_1, {duration: 0.5, x: '50%', y: '-33%', scale: 0.6, ease: 'power2.out'})
								}	
							}
					}

					if(parseInt(this.currents) <= (allSlides - 2)){ 
							let nextNextSlide = parseInt(this.currents) + 2;
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextNextSlide + '"]').classList.remove('next-active-slide');
					}

					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.remove('prev-active-slide');
					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.add('current-active-slide');
					gsap.to(wrapper, {duration: 0.5, x: '0%', y: '0%', scale: 0.8, ease: 'power2.out'})
				}
			})

			this.carousel.on( 'staticClick', (event) => {
				if(event.target.closest('.carousel--item-inner').closest('.carousel--item').classList.contains('prev-active-slide')){
					let prevSlide = parseInt(this.currents) - 1;
					let currentElement = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]');
					//let currRect = currentElement.getBoundingClientRect();
					let wrapper = currentElement.querySelector('.carousel--item-inner');
					//let wrapper_img = currentElement.querySelector('.wrapper .image picture img');

					let delta =  grid.main.start - currentElement.offsetLeft;
					gsap.to(this.container, {duration: 0.5, left: delta, ease: 'power2.out'});

					this.currents--;

					if(parseInt(this.currents) > 1){
							let prevSlide = parseInt(this.currents) - 1;
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]').classList.add('prev-active-slide');
					}

					if(parseInt(this.currents) < allSlides){
							let nextSlide = parseInt(this.currents) + 1;
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]').classList.remove('current-active-slide');
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]').classList.add('next-active-slide');
							let el_1 = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]');
							let wp_1 = el_1.querySelector('.carousel--item-inner');
							if(el_1 !== null){
								if(this.isMobile()){
									gsap.to(wp_1, {duration: 0.5, x: '-20%', y: '-33%', scale: 0.6, ease: 'power2.out'})
								}else{
									gsap.to(wp_1, {duration: 0.5, x: '50%', y: '-33%', scale: 0.6, ease: 'power2.out'})
								}	
							}
					}

					if(parseInt(this.currents) <= (allSlides - 2)){ 
							let nextNextSlide = parseInt(this.currents) + 2;
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextNextSlide + '"]').classList.remove('next-active-slide');
					}

					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.remove('prev-active-slide');
					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.add('current-active-slide');
					gsap.to(wrapper, {duration: 0.5, x: '0%', y: '0%', scale: 0.8, ease: 'power2.out'})

				}
				if(event.target.closest('.carousel--item-inner').closest('.carousel--item').classList.contains('next-active-slide')){
					let nextSlide = parseInt(this.currents) + 1;
					let currentElement = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]');
					let wrapper = currentElement.querySelector('.carousel--item-inner');

					let delta =  grid.main.start - currentElement.offsetLeft;
					gsap.to(this.container, {duration: 0.5, left: delta, ease: 'power2.out'});
					this.currents++;

					if(parseInt(this.currents) > 2){
						let prevPrevSlide = parseInt(this.currents) - 2;
						this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevPrevSlide + '"]').classList.remove('prev-active-slide');
					}

					if(parseInt(this.currents) > 0){
						let prevSlide = parseInt(this.currents) - 1;
						this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]').classList.remove('current-active-slide');
						this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]').classList.add('prev-active-slide');
						let el_3 = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]');
						let wp_3 = el_3.querySelector('.carousel--item-inner');
						if(el_3 !== null){
							if(this.isMobile()){
								gsap.to(wp_3, {duration: 0.5, x: '20%', y: '20%', scale: 0.6, ease: 'power2.out'})
							}else{
								gsap.to(wp_3, {duration: 0.5, x: '-50%', y: '20%', scale: 0.6, ease: 'power2.out'})
							}
						}
					}

					if(parseInt(this.currents) <= (allSlides - 1)){
							let nextSlide = parseInt(this.currents) + 1;
							this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]').classList.add('next-active-slide');
					}

					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.remove('next-active-slide');
					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.add('current-active-slide');
					gsap.to(wrapper, {duration: 0.5, x: '0%', y: '0%', scale: 0.8, ease: 'power2.out'})
				}
			})

			this.carousel.on( 'dragMove', () => { //( event, pointer, moveVector )
				let x = this.carousel.position.x;
				this.direction = x;
				if(this.direction < this.prevDirection){
						this.dir = 'left';
						this.prevDirection = this.direction;
				}
				if(this.direction > this.prevDirection){
						this.dir = 'right';
						this.prevDirection = this.direction;
				}

				let mrk = grid.main.start;
				let trigger = grid.main.start - grid.main.width;

				let mrk_2 = grid.next.start;
				let trigger_2 = grid.next.start - grid.next.width;

				[...elements].forEach((el) => {
					let ord = parseInt(el.getAttribute('order'));
					let rect = el.getBoundingClientRect();

					if(rect.left <= mrk && rect.left >= trigger){
						let innerIMG = el.querySelector('.carousel--item-inner')
						let perc = (rect.left - mrk) / (trigger - mrk); // 0 -> 1
						let scale = Number((0.8 - (Math.abs(perc)*0.2)).toFixed(5));
						if(this.isMobile()){
							let tran_x = Number((0 + (Math.abs(perc)*20)).toFixed(5)) + '%';
							let tran_y = Number((0 + (Math.abs(perc)*20)).toFixed(5)) + '%';
							gsap.to(innerIMG, {duration: 0.5, x: tran_x, y: tran_y, scale: scale})
						}else{
							let tran_x = Number((0 - (Math.abs(perc)*50)).toFixed(5)) + '%';
							let tran_y = Number((0 + (Math.abs(perc)*20)).toFixed(5)) + '%';
							gsap.to(innerIMG, {duration: 0.5, x: tran_x, y: tran_y, scale: scale})
						}
					}

					if(rect.left <= mrk_2 && rect.left >= trigger_2){
						let innerIMG = el.querySelector('.carousel--item-inner')
						let perc_2 = (rect.left - mrk_2) / (trigger_2 - mrk_2); // 0->1
						let scale_2 = 0.6 + Number(((Math.abs(perc_2)*0.2)).toFixed(2));

						if(this.isMobile()){
							let tran_x_2 = Number((-20 + (Math.abs(perc_2)*20)).toFixed(5)) + '%';
							let tran_y_2 = Number((-33 + (Math.abs(perc_2)*33)).toFixed(5)) + '%';
							gsap.to(innerIMG, {duration: 0.5, x: tran_x_2, y: tran_y_2, scale: scale_2})
						}else{
							let tran_x_2 = Number((50 - (Math.abs(perc_2)*50)).toFixed(5)) + '%';
							let tran_y_2 = Number((-33 + (Math.abs(perc_2)*33)).toFixed(5)) + '%';
							gsap.to(innerIMG, {duration: 0.5, x: tran_x_2, y: tran_y_2, scale: scale_2})
						}
					}

					if(this.dir == 'left'){
						let limitForAutoPosition = grid.next.start;
						if(rect.left <= limitForAutoPosition){
							this.currents = ord;
						}
					}

					if(this.dir == 'right'){
						let limitForAutoPosition = grid.next.start - grid.next.width;
						if(rect.left <= limitForAutoPosition){
							this.currents = ord;
						}
					}
				})
			});

			this.carousel.on( 'dragEnd', () => {
				let x = this.carousel.position.x;
				let currentElement = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]');
				let delta =  grid.main.start - currentElement.offsetLeft;

				this.container.querySelector('.carousel--wrapper .carousel--item[order="' + this.currents + '"]').classList.add('current-active-slide');

				if(parseInt(this.currents) > 1){
					let prevSlide = parseInt(this.currents) - 1;
					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + prevSlide + '"]').classList.add('prev-active-slide');
				}

				if(parseInt(this.currents) < allSlides){
					let nextSlide = parseInt(this.currents) + 1;
					this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nextSlide + '"]').classList.add('next-active-slide');
				}

				let total = elements.length;

				if(x < 0 || x > -limit_end){
					gsap.to(this.container, {duration: 0.5, left: delta, ease: 'power2.out'});
					let wrapper = currentElement.querySelector('.carousel--item-inner');

					if(this.currents <= (total - 1)){
						let nx = this.currents + 1;
						let el_1 = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + nx + '"]');
						let wp_1 = el_1.querySelector('.carousel--item-inner');
						if(el_1 !== null){
							if(this.isMobile()){
								gsap.to(wp_1, {duration: 0.5, x: '-20%', y: '-33%', scale: 0.6, ease: 'power2.out'})
							}else{
								gsap.to(wp_1, {duration: 0.5, x: '50%', y: '-33%', scale: 0.6, ease: 'power2.out'})
							}	
						}
					}
					if(this.currents > 1){
						let pv = this.currents - 1;
						let el_3 = this.container.querySelector('.carousel--wrapper .carousel--item[order="' + pv + '"]')
						let wp_3 = el_3.querySelector('.carousel--item-inner');
						if(el_3 !== null){
							if(this.isMobile()){
								gsap.to(wp_3, {duration: 0.5, x: '20%', y: '20%', scale: 0.6, ease: 'power2.out'})
							}else{
								gsap.to(wp_3, {duration: 0.5, x: '-50%', y: '20%', scale: 0.6, ease: 'power2.out'})
							}
						}
					}
					gsap.to(wrapper, {duration: 0.5, x: '0%', y: '0%', scale: 0.8, ease: 'power2.out'})
				}

				if(x > 0){
					gsap.to(this.container, {duration: 0.5, left: 0, ease: 'power2.out'});
				}
				if(x < -limit_end){
					gsap.to(this.container, {duration: 0.5, left: -limit_end, ease: 'power2.out'});
				}
			});
		}
	},
	beforeMount() {
		window.addEventListener('resize', () => {
			this.calcWinsize();
		});
	},
	mounted() {
		this.initCarousel();
		this.setSliderWidth();
		this.animateCarousel();
	},
}
</script>

<style lang="scss">
.carousel--application {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	.carousel--text {
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		transform: translate(-50%, -50%);
		z-index: 21;
		h3 {
			font-family: 'Suisse Int';
			font-weight: 500;
			color: $black;
			font-size: 10vw;
			line-height: 10vw;
			text-transform: uppercase;
			margin: 0;
			@include mobile {
				font-size: 15vw;
				line-height: 15vw;
			}
		}
	}
	.carousel--arrow-prev {
		position: absolute;
		top: 60%;
		left: 5vw;
		width: 5vw;
		height: auto;
		z-index: 22;
		transform: translateY(-50%) rotate(180deg);
		transform-origin: center;
		transition: opacity .35s ease-in-out 0s;
		@include mobile {
			width: 15vw;
		}
		svg {
			fill: $black;
			width: 100%;
			height: auto;
			display: block;
		}
		&.inactive {
			opacity: 0;
			transition: opacity .35s ease-in-out 0s;
		}
	}
	.carousel--arrow-next {
		position: absolute;
		top: 40%;
		right: 5vw;
		width: 5vw;
		height: auto;
		z-index: 22;
		transform: translateY(-50%);
		transition: opacity .35s ease-in-out 0s;
		@include mobile {
			width: 15vw;
		}
		svg {
			fill: $black;
			width: 100%;
			height: auto;
			display: block;
		}
		&.inactive {
			opacity: 0;
			transition: opacity .35s ease-in-out 0s;
		}
	}
	.carousel--container {
		position: relative;
		width: auto;
		z-index: 22;
		.carousel--wrapper {
			position: relative;
			display: flex;
			padding-left: calc(100% / 3);
			@include mobile {
				padding-left: 10vw;
			}
			.carousel--item {
				position: relative;
				width: calc(100vw/3);
				flex-shrink: 0;
				height: 100%;
				transition-property: transform;
				@include mobile {
					width: 80vw
				}
				.carousel--item-inner {
					position: relative;
					width: 100%;
					height: auto;
					transform-origin: center;
					transform: translate(50%, -33%) scale(0.6) ;
					img {
						position: relative;
						width: 100%;
						height: auto; 
						display: block;
					}
					@include mobile {
						transform: translate(-20%, -33%) scale(0.6);
					}
				}
				&[order="1"]{
					.carousel--item-inner {
						transform: scale(0.8) translate(0, 0);
					}
				}
			}
		}
	}
}
</style>