<template>
	<div class="application">
		<section class="application--intro" v-if="Object.keys(fields).length">
			<div class="title">
				<h3 v-for="(title, index) in fields.titolo_formattato.split('#')" :key="index" :class="{'withSvg' : thereIsAnd(title)}">
					{{title.replace('&', '')}}
					<AndSVG v-if="thereIsAnd(title)" />
				</h3>
			</div>
			<div class="image" v-if="application">
				<ImageById :media_id="application[0].featured_media" />
			</div>
			<div class="application--title" v-if="application">
				<h1>{{application[0].title.rendered}}</h1>
				<span>{{order}}</span>
			</div>
		</section>
		<section class="application--image">
			<div class="image" v-if="Object.keys(fields).length">
				<img :src="fields.immagine_applicazione" />
			</div>
		</section>
		<section v-if="fields.brevetto" class="application--tech">
			<h3>{{$t('applicazioni.tech')}}</h3>
			<router-link :to="$i18nRoute({ name: 'Patent', params: {slug: fields.brevetto.post_name} })" :title="fields.brevetto.post_title" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
				<ImageById :media_id="fields.brevetto_logo" />
			</router-link>
			<!-- <span>{{fields.brevetto.post_title}}</span> -->
		</section>
		<section class="application--text">
			<div class="text--wrapper" v-if="Object.keys(fields).length">
				<h3>
					<span v-for="(text, index) in fields.titolo_1.split(/\r?\n/)" :key="index">{{text}}</span>
				</h3>
				<p>{{fields.testo_1}}</p>
			</div>
		</section>
		<section class="application--gallery" v-if="Object.keys(fields).length">
			<CarouselApplication :gallery="fields.galleria_applicazione" :galleryText="galleryLabel" :key="$route.fullPath" />
		</section>
		<section class="application--next-page" v-if="Object.keys(next).length != 0">
			<div class="next--wrapper">
				<router-link :to="$i18nRoute({ name: 'Applications' })" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
					<div class="title">
						<h3>{{$t('applicazioni.indietro')}}</h3>
					</div>
					<!-- <div class="image">
						<ImageById :media_id="next.featured_media" />
					</div> -->
				</router-link>
			</div>
		</section>
	</div>
</template>

<script>
import axios from 'axios';
import AndSVG from '@/components/elements/AndSVG';
import CarouselApplication from '@/components/CarouselApplication';
import ImageById from '@/components/ImageById';
export default {
	data() {
		return {
			application: [],			
			featuredImage: [],
			fields: {},
			slug: this.$route.params.slug,
			current_language : this.$route.params.locale,
			order: '',
			galleryLabel: 'View the#gallery', 
			next: {},
			headTitle: ''
		}
	},
	components: {
		AndSVG,
		CarouselApplication,
		ImageById
	},
	metaInfo() {
		return{
			title: this.headTitle,
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {
		getImageFromMediaID(media_id){
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/media/' + media_id)
				.then(response => {
					this.featuredImage = response.data.media_details.sizes.full.source_url
				})
			return this.featuredImage;
		},
		thereIsAnd(string){
			if(string.includes('&')){
				return true;
			}else{
				return false;
			}
		},
		getOrder(){
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/application/?orderby=title&order=asc')
				.then(response => {
					response.data.map((res, ind) => {
						if (res.slug == this.slug) {
							if(!response.data[ind+1]){
								this.next = response.data[0]
							}else{
								this.next = response.data[ind+1]
							}
							if(ind > 9){
								this.order = ind;
							}else{
								this.order = '0' + ind;
							}
						}
					});
				});
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/application/?slug=' + this.slug + '&lang=' + this.current_language)
				.then(response => {
					this.application = response.data;
					this.headTitle = this.application[0].title.rendered
					axios
						.get(process.env.VUE_APP_API_URL + '/wl/v1/acf/getfieldbyid/' + response.data[0].id)
						.then(response => {
							this.fields = response.data
							//console.log(this.fields);
						})
				});
		} 
	},
	watch: {
		'$route.params.slug': function(){
			this.slug = this.$route.params.slug
			this.getOrder();
		},
		'$route.params.locale': function(){
			this.current_language = this.$route.params.locale
			this.getOrder();
		}
	},
	beforeMount() {
		this.getOrder();
	}
}
</script>

<style lang="scss">
.application {
	position: relative;
	width: 100%;
	height: auto;
	min-height: 100vh;
	@include mobile {
		padding-top: 60px;
	}
	.application--intro {
		position: relative;
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		@include mobile {
			height: auto;
			padding: 10vh 0
		}
		.title {
			position: relative;
			width: 65vw;
			display: flex;
			flex-direction: column;
			@include mobile {
				width: 80vw;
			}
			h3 {
				font-family: 'Suisse Int';
				font-weight: 500;
				color: $black;
				font-size: 10vw;
				line-height: 10vw;
				text-transform: uppercase;
				margin: 0;
				@include mobile {
					font-size: 15vw;
					line-height: 15vw;
				}
				svg {
					position: relative;
					height: 10vw;
					width: auto;
					display: inline-block;
					margin-left: 1vw;
					path {
						fill: $black;
					}
				}
				&.withSvg {
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
				&:first-of-type {
					align-self: flex-start;
				}
				&:nth-of-type(2) {
					align-self: flex-end;
				}
				&:last-of-type {
					align-self: center;
				}
			}
		}
		.image {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 20vw;
			img {
				width: 100%;
				height: auto;
				display: block;
			}
			@include mobile {
				width: 50vw
			}
		}
		.application--title {
			position: absolute;
			bottom: 1vw;
			left: 1vw;
			width: calc(100% - 2vw);
			height: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include mobile {
				display: none;
			}
			h1, span {
				margin: 0;
				text-transform: uppercase;
				font-family: 'Suisse Int';
				font-size: 14px;
				line-height: 14px;
				font-weight: 600;
				color: $black;
			}
		}
	}
	.application--image {
		position: relative;
		width: 100%;
		height: auto;
		padding: 10vh 0;
		display: flex;
		justify-content: center;
		align-items: center;
		.image {
			position: relative;
			width: 40vw;
			height: auto;
			@include mobile {
				width: 70vw;
			}
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
	.application--text {
		position: relative;
		width: 100%;
		padding: 20vh 0;
		display: flex;
		justify-content: center;
		align-items: center;		
		@include mobile {
			padding: 10vh 0;
		}
		.text--wrapper {
			position: relative;
			width: 50%;
			height: auto;
			display: flex;
			flex-direction: column;			
			@include mobile {
				padding: 0 5vw;
				width: 100%;
			}
			h3 {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin: 0 0 20vh 0;				
				@include mobile {
					margin: 0 0 5vh 0;
				}
				span {
					text-transform: uppercase;
					font-family: 'Saol Display';
					font-size: 4vw;
					line-height: 4vw;
					font-weight: 400;
					color: $black;
					@include mobile {
						line-height: 8vw;
						font-size: 8vw;
					}
				}
			}
			p {
				color: $black;
				font-size: 24px;
				font-weight: 400;
				font-family: 'Suisse Int';
				width: 70%;
				align-self: flex-end;
				@include mobile {
					width: 100%;
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
	}
	.application--tech {
		position: relative;
		width: 100%;
		padding: 20vh 0 0 0 ;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		@include mobile {
			padding: 10vh 0 0 0;
		}
		h3 {
			font-family: 'Suisse Int';
			font-weight: 500;
			color: $black;
			font-size: 20px;
			line-height: 20px;
			text-transform: uppercase;
			margin: 0 0 2vh 0;
		}
		a {
			position: relative;
			width: 10vw;
			display: flex;
			@include mobile {
				width: 70vw;
			}
			img {
				position: relative;
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
	.application--gallery {
		position: relative;
		width: 100%;
		height: 100%;
		padding-bottom: 20vh;
		@include mobile {
			padding-bottom: 10vh;
		}
	}
	.application--next-page {
		position: relative;
		width: 100%;
		height: 100vh;
		background-color: $red;
		display: flex;
		align-items: center;
		justify-content: center;
		@include mobile {
			height: 50vh;
		}
		.next--wrapper {
			position: relative;
			width: 100%;
			height: auto;
			@include mobile {
				width: 100%;
				height: 100%;
			}
			a {
				position: relative;
				display: block;
				@include mobile {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
				}
				.title {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					text-align: center;
					@include mobile {
						width: 80%;
						z-index: 6;
					}
					h3 {
						font-family: 'Suisse Int';
						font-weight: 500;
						color: $white;
						font-size: 8vw;
						line-height: 8vw;
						text-transform: uppercase;
						margin: 0;
						@include mobile {
							font-size: 15vw;
							line-height: 15vw;
						}
					}
				}
				.image {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 20vw;
					height: auto;
					transform: translate(-50%, -50%);
					@include mobile {
						width: 50vw;
						z-index: 5;
					}
					img {
						width: 100%;
						height: auto;
						display: block;
					}
				}
			}
		}
	}
}
</style>